// Resources
import API from "../helpers/API"
import GeneralTools from "../helpers/GeneralTools"

import { validateBenefitsValue, getAdditionalName, getPriceFlavour, getPriceAddition, getTotalOrder } from "../helpers/OrderTools"

/**
 * Componente funcional para manipulacão dos dados de pedidos
 */
const OrderController = {
  insert(order, customer, orderType, scheduleOrder) {
    const [total] = getTotalOrder(order)
    // Definindo os parametros
    let params = {
      id_modality: order.id_modality,
      uuid_customer: customer.uuid_customer,
      id_street_customer: customer.id_street_customer,
      id_customer_address: customer.id_customer_address,
      ds_origin: "site",
      ds_obs: null,
      ds_delivery_obs: order.deliveryComments,
      ds_discount_type: order.discountType,
      val_total: total,
      val_discount: order.discountType === "R$" ? GeneralTools.parseFloat(order.discount || 0) : null,
      pc_discount: order.discountType === "%" ? GeneralTools.parseFloat(order.discount || 0) : null,
      ds_discount: order.ds_discount,
      val_benefits: 0,
      val_delivery_tax: order.id_modality === 1 ? GeneralTools.parseFloat(order.deliveryFee) : 0,
      val_additional_tax: 0,
      delivery_time: order.deliveryTime,
      st_approved: false,
      st_scheduled: order.scheduled,
      st_resended: order.st_resended,
      dt_schedule_start: order.scheduleTime,
      dt_schedule_end: order.scheduleTime,
    }
    let fidelity = order.fidelity?.id_fidelity || null

    if (order.benefits.length > 0) {
      params.benefits = order.benefits.map((benefit) => {
        if (!fidelity && !!benefit.id_fidelity) fidelity = benefit.id_fidelity
        const valBenefit = validateBenefitsValue(order, benefit)
        params.val_benefits = params.val_benefits + valBenefit
        return {
          ...benefit,
          val_benefit: valBenefit,
          nm_sponsorship: "site",
        }
      })
    } else {
      params.val_benefits = 0
    }

    let newCombos = [...order.combos]
    let newOrderProducts = [...order.products]
    let newOrderBeverages = [...order.beverages]

    params.combos = newCombos.map(({ id_combo, uuid_combo, nro_combo, nm_combo, amount, val_price, ds_obs }) => {
      return {
        id_combo,
        uuid_combo,
        nro_combo,
        nm_combo,
        amount,
        ds_obs,
        val_price: GeneralTools.parseFloat(val_price),
      }
    })

    params.products = newOrderProducts.map((product) => {
      //Função para organizar os complementos do produto
      const getComplementsItems = (complements) => {
        const getPriceComplement = (complement, additional) => {
          const combo = newCombos.find(({ nro_combo }) => nro_combo === product.nro_combo)
          if (!!combo) {
            const addPrice = additional.val_price
            const complPrice = complement.val_price

            return complPrice + addPrice
          } else {
            let complementValue = 0
            if (!!additional.sizes) {
              complementValue = additional.sizes.find(({ id_size }) => id_size === product.size.id_size).additional_size.val_price
            } else if (!!additional.size) {
              complementValue = additional.size.additional_size.val_price
            }

            if (`${additional.nm_additional}`.toLowerCase().indexOf("sem", 0) === -1) {
              if (!!complement.sizes) {
                complementValue = complementValue + complement.sizes.find(({ id_size }) => id_size === product.size.id_size).complement_size.val_price
              } else if (!!complement.size) {
                complementValue = complementValue + complement.size.complement_size.val_price
              }
            }
            return GeneralTools.parseFloat(complementValue)
          }
        }

        complements.forEach((complement) => {
          if (!!complement.complements) complement.additionals = complement.complements
          complement.additionals.forEach((additional) => {
            additionals.push({
              id_complement: complement.id_complement,
              id_additional: additional.id_additional,
              uuid_complement: complement.uuid_complement,
              uuid_additional: additional.uuid_additional,
              nm_complement: complement.nm_complement,
              nm_additional: getAdditionalName(additional, complement),
              amount: complement.amount || 1,
              val_price: getPriceComplement(complement, additional),
            })
          })
        })
      }

      const getTotalProduct = (product) => {
        if (!!product.total) return product.total
        return 0
      }

      const newProduct = { ...product }

      let flavours = product.flavours.map(
        ({
          uuid_flavour,
          id_flavour,
          nm_flavour,
          ds_flavour = null,
          ds_obs = null,
          size = null,
          sizes = [],
          restrictions = [],
          additions = [],
          lb_flavour_prefix = null,
          st_emphasize = false,
          amount,
        }) => {
          const val_price = getPriceFlavour(!!newProduct.id_combo, newProduct, id_flavour, size)
          return {
            id_flavour,
            uuid_flavour,
            nm_flavour,
            ds_flavour,
            ds_obs,
            amount,
            val_price: !!newProduct.id_combo ? val_price : val_price / (!!amount ? amount : 1),
            lb_flavour_prefix,
            st_emphasize,
            restrictions: restrictions.map(({ uuid_ingredient, id_ingredient, nm_ingredient }) => {
              return { uuid_ingredient, id_ingredient, nm_ingredient }
            }),
            optionals: additions.map(({ id_ingredient, uuid_ingredient, nm_ingredient, amount }, index) => {
              return {
                id_ingredient,
                uuid_ingredient,
                nm_ingredient,
                amount,
                val_price: getPriceAddition(order, product, additions[index], id_ingredient, nm_ingredient, amount, product.size, sizes),
                // val_price: !!size
                //   ? (size.ingredient_size.val_price / product.flavours.length) * amount
                //   : (sizes.find(({ uuid_size }) => uuid_size === product.size.uuid_size).val_price / product.flavours.length) * amount,
              }
            }),
          }
        }
      )

      // Verificando se é maior
      if (product.size?.st_higher_price || false) {
        let newFlavours = []
        flavours.sort((a, b) => b.val_price - a.val_price)
        let quantyFlavourMaxValue = 1
        for (let index = 0; index < flavours.length; index++) {
          const { id_flavour, val_price, amount } = flavours[index]
          if (quantyFlavourMaxValue > 0) {
            let amountOfThisFlavour = flavours.reduce((tot, current) => {
              return tot + (current.id_flavour === id_flavour ? 1 : 0 || 0)
            }, 0)
            if (index === 0) {
              quantyFlavourMaxValue = amountOfThisFlavour - 1
            } else {
              quantyFlavourMaxValue--
            }
            newFlavours.push({
              ...flavours[index],
              val_price: val_price / amountOfThisFlavour,
            })
          } else {
            newFlavours.push({
              ...flavours[index],
              val_price: 0,
            })
          }
        }
        flavours = newFlavours
      }

      //Adicionais são os complementos do produto
      let additionals = []
      if (!!product.complements) {
        getComplementsItems(product.complements)
      }

      if (!fidelity && !!product.id_fidelity) fidelity = product.id_fidelity
      return {
        id_combo: product.id_combo || null,
        nro_combo: product.nro_combo || null,
        id_product: product.fk_id_product || product.id_product,
        nro_product: product.nro_product || null,
        uuid_product: product.uuid_product,
        nm_product: product.nm_product,
        amount: product.amount,
        val_price: getTotalProduct(product),
        ds_obs: product.ds_obs,
        dough: {
          id_dough: 1,
          nm_dough: "Normal",
        },
        size: {
          id_size: product.size.id_size,
          uuid_size: product.size.uuid_size,
          nm_size: product.size.nm_size,
          val_price: product.size.val_price,
          val_delivery_payment: product.size.val_delivery_payment,
        },
        flavours,
        additionals,
      }
    })

    params.beverages = newOrderBeverages.map(
      ({
        id_combo = null,
        nro_combo = null,
        id_product = null,
        nro_product = null,
        id_fidelity = null,
        uuid_beverage,
        id_beverage,
        nm_beverage,
        amount,
        val_price = 0,
      }) => {
        if (!fidelity && !!id_fidelity) fidelity = id_fidelity

        return {
          id_combo,
          nro_combo,
          id_product,
          nro_product,
          uuid_beverage,
          id_beverage,
          nm_beverage,
          amount,
          val_price: GeneralTools.parseFloat(val_price),
        }
      }
    )
    //A rotina de inserir o cupom já ajusta o valor do subtotal do order para que seja exibido no restante do ste o valor atualizado.
    // Removi a parte no qual ele removia o valor do cupom do total do pagamento, desta forma não será mais excluido
    //  em duplicidade o valor do cupom na hora do troco.
    params.payments = order.payments.map(({ id_payment_method, nm_payment_method, val_receivable, val_payment }) => {
      return {
        id_payment_method,
        nm_payment: nm_payment_method,
        val_receivable: GeneralTools.parseFloat(val_receivable),
        val_payment: GeneralTools.parseFloat(val_payment),
      }
    })

    if (fidelity) {
      params.id_fidelity = fidelity
      // if (order.fidelity.)
      //
      if (!!order.fidelity) {
        const { prize_discount = 0, prize_discount_type = false } = order.fidelity
        if (prize_discount > 0) {
          if (prize_discount_type) {
            params.pc_discount = prize_discount
          } else {
            params.val_discount = prize_discount
          }
        }

        // totalDiscount += fidelityDiscount
      }
    }

    //ALterando o order_json após o spread
    params = {
      ...params,
      order_json: {
        ...order,
        newOrderBeverages,
        newOrderProducts,
        newCombos,
        beverages: [...params.beverages],
        products: [...params.products],
        combos: [...params.combos],
        customer,
        device: {
          version: process.env.REACT_APP_VERSION,
          witdh: window.innerWidth,
          height: window.innerHeight,
          userAgent: window.navigator.userAgent,
        },
      },
    }

    if (orderType === "schedule") {
      params = {
        ...params,
        id_neighbourhood: customer.id_neighbourhood,
        st_approved: true,
      }

      return API.request("/v1/schedule/order", { ...params, scheduleOrder }, "POST")
    }
    return API.request("/v1/order", params, "POST", {}, 60000)
  },

  fetchOne(orderUid) {
    return API.request("/v1/order/one/" + orderUid, [], "GET")
  },

  fetchProgress(uuid_order) {
    return API.request("/v1/order/progress/" + uuid_order, [], "GET")
  },

  fetchAllByCustomer(uuid_customer) {
    return API.request("/v1/order/customer/" + uuid_customer, [], "GET")
  },

  countOrdersByCustomer(uuid_customer, endDate = null, startDate = null) {
    const params = { endDate, startDate }
    return API.request("/v1/order/customer/" + uuid_customer + "/count", params, "GET")
  },
}

export default OrderController
